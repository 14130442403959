/* eslint-disable no-restricted-syntax */
import { useEffect, useRef, useState } from 'react';
import { useHaccpHazardsQuery } from 'lib/queries';

/*
  TODO for the sake of the refactoring
  Both format date utils have been renamed.
  However, it is advised to use only one formatDate utils
  that receives an ISO-compliant formatter (https://fr.wikipedia.org/wiki/ISO_8601).
*/
export const formatDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const getItemTaxonomyProperty = (property, taxonomy, taxonomies) => {
  if (taxonomy) {
    const itemTaxonomy = taxonomies.items.filter(
      (item) => item.id === taxonomy.id,
    );
    return itemTaxonomy[0][property];
  }
  return '';
};

export const formatDateForQuery = (date) => {
  if (date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${`0${date.getDate()}`.slice(-2)}`;
  }
  return '';
};

export const sortOptions = [
  {
    label: 'Date de publication (Du plus récent au plus ancien)',
    value: '-source_published_at,source__name,source_type__name,title',
  },
  {
    label: 'Date de publication (Du plus ancien au plus récent)',
    value: 'source_published_at,source__name,source_type__name,title',
  },
  {
    label: 'Source (Par ordre alphabétique)',
    value: 'source__name,source_type__name,-source_published_at,title',
  },
  {
    label: 'Type (Par ordre alphabétique)',
    value: 'source_type__name,source__name,-source_published_at,title',
  },
];

export const sortPackagingOptions = [
  {
    label: 'Date de publication (Du plus récent au plus ancien)',
    value: '-source_published_at,source__name,title',
  },
  {
    label: 'Date de publication (Du plus ancien au plus récent)',
    value: 'source_published_at,source__name,title',
  },
  {
    label: 'Source (Par ordre alphabétique)',
    value: 'source__name,-source_published_at,title',
  },
  {
    label: "Etat d'avancement (Par ordre alphabétique)",
    value: 'research_status__name,source__name,-source_published_at,title',
  },
];

export const watchPeriodicityOptions = [
  {
    label: '1 mois',
    value: 'MONTH',
  },
  {
    label: '2 mois',
    value: 'TWO_MONTH',
  },
  {
    label: '3 mois',
    value: 'THREE_MONTH',
  },
  {
    label: '6 mois',
    value: 'SIX_MONTH',
  },
];

export const useVisible = (initialIsVisible) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export const resetLocalStorageFilter = (filterName) => {
  localStorage.setItem(`${filterName}-filter`, '');
  const filterNameLength = filterName.length;
  for (const key in localStorage) {
    if (key.substring(0, filterNameLength) === filterName) {
      localStorage.removeItem(key);
    }
  }
};

export const formattedTopics = (topicsToDisplay, allTopics) =>
  topicsToDisplay.map((topic) => {
    const parentName = getItemTaxonomyProperty('parent', topic, allTopics);
    const topicName = getItemTaxonomyProperty('name', topic, allTopics);
    if (parentName) {
      return `${parentName} > ${topicName}`;
    }
    return topicName;
  });

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function capitalize() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const setItemsFilters = (
  oldFilters,
  setFilters,
  element,
  elementId,
  filterToManage,
) => {
  const newFilters = { ...oldFilters };
  if (
    filterToManage.filter === 'source' ||
    filterToManage.filter === 'source_type' ||
    filterToManage.filter === 'research_status'
  ) {
    let newArray = [...oldFilters[filterToManage.filter]];
    if (filterToManage.value.length === 0) {
      newArray = [];
    } else if (filterToManage.state) {
      newArray.push(filterToManage.value[0]);
    } else {
      const index = newArray.indexOf(filterToManage.value[0]);
      if (index > -1) {
        newArray.splice(index, 1);
      }
    }
    newFilters[filterToManage.filter] = newArray;
  } else if (filterToManage.filter === 'new_items_only') {
    newFilters.new_items_only = filterToManage.state;
    if (newFilters.new_items_only) {
      newFilters.source_published_after = `${formatDateForQuery(
        new Date(Date.now()),
      ).slice(0, -2)}01`;
      newFilters.source_published_before = '';
    } else {
      newFilters.source_published_after = '';
    }
  } else if (filterToManage.filter === 'reset_all') {
    newFilters.research_status = [];
    newFilters.source = [];
    newFilters.source_type = [];
  } else {
    newFilters[filterToManage.filter] = filterToManage.value;
  }
  newFilters.request = `&${element}=${elementId}`;
  newFilters.research_status.forEach((researchStatusId) => {
    newFilters.request += `&research_status=${researchStatusId}`;
  });
  newFilters.source.forEach((sourceId) => {
    newFilters.request += `&source=${sourceId}`;
  });
  newFilters.source_type.forEach((sourceTypeId) => {
    newFilters.request += `&source_type=${sourceTypeId}`;
  });
  if (newFilters.new_items_only) {
    newFilters.request += `&source_published_after=${newFilters.source_published_after}`;
  } else {
    newFilters.request += `&source_published_before=${newFilters.source_published_before}`;
    newFilters.request += `&source_published_after=${newFilters.source_published_after}`;
  }
  if (newFilters.order) newFilters.request += newFilters.order;
  setFilters(newFilters);
};

export const getSubdomain = () => {
  const subdomainTemp = window.location.host.split('.')[0];
  if (subdomainTemp === 'pack') return 'pack';
  if (subdomainTemp === 'haccp') return 'haccp';
  return 'fl';
};

export const filterStrToFilterIdsArray = (filterStr, termToSplit) => {
  const filterIdsArray = filterStr.split(termToSplit);
  filterIdsArray.shift();
  return filterIdsArray;
};

export const filterStrToFilterIdsStr = (filterStr, termToSplit) =>
  filterStrToFilterIdsArray(filterStr, termToSplit).join(',');

export const sortStrIds = (ids) => {
  const idsArray = ids.split(',');
  idsArray.sort();
  return idsArray.join(',');
};

export const manageHaccpItemLiStyleAttributes = (item) => {
  const hazards = useHaccpHazardsQuery();

  if (hazards.status === 'success') {
    return {
      borderLeft: `6px solid ${
        hazards.data.items.find((hazard) => hazard.id === item.hazard.id).color
      }`,
    };
  }

  return {};
};
