/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

import Spinner from '../components/Spinner';
import Notifications from '../components/Notifications';
import AppRoutes from '../routes';
import { AppWrapper } from '../lib/context';
import { getCookie } from '../lib/tools';

const queryConfig = {
  queries: {
    retry: false,
    staleTime: 1000 * 60,
    useErrorBoundary: true,
  },
};

const queryClient = new QueryClient({ defaultOptions: queryConfig });

const AppProvider = () => {
  axios.defaults.headers.delete['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.patch['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');

  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
      <Router>
        <Suspense
          fallback={
            <div className='vh-100 vw-100 d-flex align-items-center justify-content-center'>
              <Spinner size='xl' />
            </div>
          }
        >
          <AppWrapper>
            <Notifications />
            <AppRoutes />
          </AppWrapper>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
};

export default AppProvider;
