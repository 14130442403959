import React from 'react';
import AppProvider from './providers/app';
import { getSubdomain } from './lib/tools';

if (getSubdomain() === 'fl') import('./assets/scss/index_fl.scss');
if (getSubdomain() === 'pack') import('./assets/scss/index_pack.scss');
if (getSubdomain() === 'haccp') import('./assets/scss/index_haccp.scss');

const App = () => <AppProvider />;

export default App;
