/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { getSubdomain, resetLocalStorageFilter } from './tools';

const AppContext = createContext([]);

export const useAppContext = () => useContext(AppContext);

export const AppWrapper = ({ children }) => {
  // Common states
  const [activeItemId, setActiveItemId] = useState();
  const [endDateFilter, setEndDateFilter] = useState('');
  const [hasFocusToDisplay, setHasFocusToDisplay] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [order, setOrder] = useState('');
  const [search, setSearch] = useState('');
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState('');
  // Foodlaw states
  const [domainFilter, setDomainFilter] = useState('');
  const [sourceFilter, setSourceFilter] = useState('');
  const [textTypeFilter, setTextTypeFilter] = useState('');
  const [topicFilter, setTopicFilter] = useState('');
  // Packaging states
  const [packagingApplicationFilter, setPackagingApplicationFilter] =
    useState('');
  const [packagingSourceFilter, setPackagingSourceFilter] = useState('');
  const [packagingStatusFilter, setPackagingStatusFilter] = useState('');
  const [packagingTopicFilter, setPackagingTopicFilter] = useState('');
  // HACCP states
  const [haccpFoodFilter, setHaccpFoodFilter] = useState('');
  const [haccpHazardFilter, setHaccpHazardFilter] = useState('');
  const [haccpSourceFilter, setHaccpSourceFilter] = useState('');
  const [haccpUpdatedOnlyFilter, setHaccpUpdatedOnlyFilter] = useState('');

  useEffect(() => {
    const subdomain = getSubdomain();
    setEndDateFilter(
      localStorage.getItem('end-date-filter')
        ? localStorage.getItem('end-date-filter')
        : '',
    );
    setOrder(
      localStorage.getItem('order') ? localStorage.getItem('order') : '',
    );
    setSearch(
      localStorage.getItem('search') ? localStorage.getItem('search') : '',
    );
    setStartDateFilter(
      localStorage.getItem('start-date-filter')
        ? localStorage.getItem('start-date-filter')
        : '',
    );
    if (subdomain === 'fl') {
      setDomainFilter(
        localStorage.getItem('domains-filter')
          ? localStorage.getItem('domains-filter')
          : '',
      );
      setSourceFilter(
        localStorage.getItem('source-filter')
          ? localStorage.getItem('source-filter')
          : '',
      );
      setTextTypeFilter(
        localStorage.getItem('source_type-filter')
          ? localStorage.getItem('source_type-filter')
          : '',
      );
      setTopicFilter(
        localStorage.getItem('topics-filter')
          ? localStorage.getItem('topics-filter')
          : '',
      );
    }
    if (subdomain === 'pack') {
      setPackagingApplicationFilter(
        localStorage.getItem('applications-filter')
          ? localStorage.getItem('applications-filter')
          : '',
      );
      setPackagingSourceFilter(
        localStorage.getItem('source-filter')
          ? localStorage.getItem('source-filter')
          : '',
      );
      setPackagingStatusFilter(
        localStorage.getItem('research_status-filter')
          ? localStorage.getItem('research_status-filter')
          : '',
      );
      setPackagingTopicFilter(
        localStorage.getItem('topics-filter')
          ? localStorage.getItem('topics-filter')
          : '',
      );
    }
    if (subdomain === 'haccp') {
      setHaccpFoodFilter(
        localStorage.getItem('foods-filter')
          ? localStorage.getItem('foods-filter')
          : '',
      );
      setHaccpHazardFilter(
        localStorage.getItem('hazard-filter')
          ? localStorage.getItem('hazard-filter')
          : '',
      );
      setHaccpSourceFilter(
        localStorage.getItem('source-filter')
          ? localStorage.getItem('source-filter')
          : '',
      );
      setHaccpUpdatedOnlyFilter(
        localStorage.getItem('updated-only-filter')
          ? localStorage.getItem('updated-only-filter')
          : '',
      );
    }
  }, []);

  const onSetFilter = (
    filterIds,
    newState,
    filterName,
    oldFilter,
    resetOldState,
  ) => {
    if (filterIds.length === 0) {
      resetLocalStorageFilter(filterName);
      return '';
    }
    let newFilter = resetOldState ? '' : oldFilter;
    filterIds.forEach((filterId) => {
      localStorage.setItem(`${filterName}-${filterId}`, newState);
      if (newState) {
        newFilter += `&${filterName}=${filterId}`;
      } else {
        newFilter = newFilter.replace(`&${filterName}=${filterId}`, '');
      }
      localStorage.setItem(`${filterName}-filter`, newFilter);
    });
    return newFilter;
  };

  // Foodlaw filters
  const onSetDomainFilter = (filterIds, newState, resetOldState) => {
    setDomainFilter(
      onSetFilter(filterIds, newState, 'domains', domainFilter, resetOldState),
    );
  };

  const onSetTopicFilter = (filterIds, newState, resetOldState) => {
    setTopicFilter(
      onSetFilter(filterIds, newState, 'topics', topicFilter, resetOldState),
    );
  };

  const onSetSourceFilter = (filterIds, newState, resetOldState) => {
    setSourceFilter(
      onSetFilter(filterIds, newState, 'source', sourceFilter, resetOldState),
    );
  };

  const onSetTextTypeFilter = (filterIds, newState, resetOldState) => {
    setTextTypeFilter(
      onSetFilter(
        filterIds,
        newState,
        'source_type',
        textTypeFilter,
        resetOldState,
      ),
    );
  };

  // Packaging filters
  const onSetPackagingApplicationFilter = (
    filterIds,
    newState,
    resetOldState,
  ) => {
    setPackagingApplicationFilter(
      onSetFilter(
        filterIds,
        newState,
        'applications',
        packagingApplicationFilter,
        resetOldState,
      ),
    );
  };

  const onSetPackagingSourceFilter = (filterIds, newState, resetOldState) => {
    setPackagingSourceFilter(
      onSetFilter(
        filterIds,
        newState,
        'source',
        packagingSourceFilter,
        resetOldState,
      ),
    );
  };

  const onSetPackagingStatusFilter = (filterIds, newState, resetOldState) => {
    setPackagingStatusFilter(
      onSetFilter(
        filterIds,
        newState,
        'research_status',
        packagingStatusFilter,
        resetOldState,
      ),
    );
  };

  const onSetPackagingTopicFilter = (filterIds, newState, resetOldState) => {
    setPackagingTopicFilter(
      onSetFilter(
        filterIds,
        newState,
        'topics',
        packagingTopicFilter,
        resetOldState,
      ),
    );
  };

  // HACCP filters
  const onSetHaccpFoodFilter = (filterIds, newState, resetOldState) => {
    setHaccpFoodFilter(
      onSetFilter(filterIds, newState, 'foods', haccpFoodFilter, resetOldState),
    );
  };

  const onSetHaccpHazardFilter = (filterIds, newState, resetOldState) => {
    setHaccpHazardFilter(
      onSetFilter(
        filterIds,
        newState,
        'hazard',
        haccpHazardFilter,
        resetOldState,
      ),
    );
  };

  const onSetHaccpSourceFilter = (filterIds, newState, resetOldState) => {
    setHaccpSourceFilter(
      onSetFilter(
        filterIds,
        newState,
        'source',
        haccpSourceFilter,
        resetOldState,
      ),
    );
  };

  const providerValue = useMemo(
    () => ({
      activeItemId,
      domainFilter,
      endDateFilter,
      haccpFoodFilter,
      haccpHazardFilter,
      haccpSourceFilter,
      haccpUpdatedOnlyFilter,
      hasFocusToDisplay,
      notifications,
      onSetDomainFilter,
      onSetHaccpFoodFilter,
      onSetHaccpHazardFilter,
      onSetHaccpSourceFilter,
      onSetPackagingApplicationFilter,
      onSetPackagingSourceFilter,
      onSetPackagingStatusFilter,
      onSetPackagingTopicFilter,
      onSetSourceFilter,
      onSetTextTypeFilter,
      onSetTopicFilter,
      order,
      packagingApplicationFilter,
      packagingSourceFilter,
      packagingStatusFilter,
      packagingTopicFilter,
      search,
      setActiveItemId,
      setEndDateFilter,
      setHaccpUpdatedOnlyFilter,
      setHasFocusToDisplay,
      setNotifications,
      setOrder,
      setSearch,
      setSidebarToggle,
      setStartDateFilter,
      sourceFilter,
      sidebarToggle,
      startDateFilter,
      textTypeFilter,
      topicFilter,
    }),
    [
      activeItemId,
      domainFilter,
      endDateFilter,
      haccpFoodFilter,
      haccpHazardFilter,
      haccpSourceFilter,
      haccpUpdatedOnlyFilter,
      hasFocusToDisplay,
      notifications,
      order,
      packagingApplicationFilter,
      packagingSourceFilter,
      packagingStatusFilter,
      packagingTopicFilter,
      search,
      sourceFilter,
      sidebarToggle,
      startDateFilter,
      textTypeFilter,
      topicFilter,
    ],
  );

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
