/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import App from './App';

registerLocale('fr', fr);
setDefaultLocale('fr');

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tunnel: '/api/bugs/',
    ignoreErrors: [/Session expired L8L/],
  });
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('isUserLogged');
      window.location.href = '/login/';
      return Promise.reject(new Error('Session expired L8L'));
    }
    return Promise.reject(error);
  },
);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
