import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useAppContext } from '../lib/context';
import { getSubdomain } from '../lib/tools';

const About = lazy(() => import('../pages/About'));
const AllWatch = lazy(() => import('../pages/AllWatch'));
const CustomFoodlawWatchResult = lazy(() =>
  import('../pages/CustomFoodlawWatchResult'),
);
const CustomPackagingWatchResult = lazy(() =>
  import('../pages/CustomPackagingWatchResult'),
);
const Disclaimer = lazy(() => import('../pages/Disclaimer'));
const DownloadRedirect = lazy(() => import('../pages/DownloadRedirect'));
const FolderContent = lazy(() => import('../pages/FolderContent'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ForgotPasswordConfirm = lazy(() =>
  import('../pages/ForgotPasswordConfirm'),
);
const Interests = lazy(() => import('../pages/Interests'));
const ItemStandAlone = lazy(() => import('../pages/ItemStandalone'));
const Login = lazy(() => import('../pages/Login'));
const MyWatches = lazy(() => import('../pages/MyWatches'));
const MyBackups = lazy(() => import('../pages/MyBackups'));
const SentryCheck = lazy(() => import('../pages/SentryCheck'));
const UserSettings = lazy(() => import('../pages/UserSettings'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const TextOfInterest = lazy(() => import('../pages/TextOfInterest'));

const AppRoutes = () => {
  const { setSearch } = useAppContext();
  const subdomain = getSubdomain();
  const location = useLocation();

  useEffect(() => setSearch(''), [location]);

  return (
    <Routes>
      <Route path='/forgot-password/' element={<ForgotPassword />} />
      <Route
        path='/forgot-password/confirm/:uid/:token/'
        element={<ForgotPasswordConfirm />}
      />
      <Route path='/login/' element={<Login />} />
      <Route path='/check/' element={<SentryCheck />} />
      <Route
        path='/'
        element={
          <PrivateRoute>
            <AllWatch />
          </PrivateRoute>
        }
      />
      <Route
        path='/about/'
        element={
          <PrivateRoute>
            <About />
          </PrivateRoute>
        }
      />
      <Route
        path='/custom-watches/'
        element={
          <PrivateRoute>
            <MyWatches />
          </PrivateRoute>
        }
      />
      <Route
        path='/custom-watches/:id/'
        element={
          <PrivateRoute>
            {subdomain === 'fl' && <CustomFoodlawWatchResult />}
            {subdomain === 'pack' && <CustomPackagingWatchResult />}
          </PrivateRoute>
        }
      />
      <Route
        path='/disclaimer/'
        element={
          <PrivateRoute>
            <Disclaimer />
          </PrivateRoute>
        }
      />
      <Route
        path='/interests/'
        element={
          <PrivateRoute>
            <Interests />
          </PrivateRoute>
        }
      />
      <Route
        path='/interest/:slug/'
        element={
          <PrivateRoute>
            <TextOfInterest />
          </PrivateRoute>
        }
      />
      <Route
        path='/item/:id/'
        element={
          <PrivateRoute>
            <ItemStandAlone />
          </PrivateRoute>
        }
      />
      <Route
        path='/my-backups/'
        element={
          <PrivateRoute>
            <MyBackups />
          </PrivateRoute>
        }
      />
      <Route
        path='/my-backups/:id/'
        element={
          <PrivateRoute>
            <FolderContent />
          </PrivateRoute>
        }
      />
      <Route
        path='/download/*'
        element={
          <PrivateRoute>
            <DownloadRedirect />
          </PrivateRoute>
        }
      />
      <Route
        path='/user-settings/'
        element={
          <PrivateRoute>
            <UserSettings />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
