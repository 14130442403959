/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { formatDateForQuery, getSubdomain } from './tools';

import { useAppContext } from './context';

const getAbout = async () => {
  const { data } = await axios.get(
    `/api/v2/pages/?type=home.ContentPage&fields=*&slug=about`,
  );
  return data;
};

const getCurrentUser = async () => {
  const { data } = await axios.get(`/api/auth/user/`);
  return data;
};

const getCustomWatch = async (customWatchId) => {
  const { data } = await axios.get(`/api/fl/watch/${customWatchId}/`);
  return data;
};

const getCustomWatches = async () => {
  const { data } = await axios.get(`/api/fl/watch/?fields=*&limit=100`);
  return data;
};

const getDisclaimer = async () => {
  const { data } = await axios.get(
    `/api/v2/pages/?type=home.ContentPage&fields=*&slug=disclaimer`,
  );
  return data;
};

const getDomains = async () => {
  const { data } = await axios.get(`/api/v2/domains/?fields=*&limit=100`);
  return data;
};

const getFocus = async () => {
  const { data } = await axios.get(
    `/api/v2/pages/?type=focus.FocusEntry&fields=*&limit=100&published_after=${formatDateForQuery(
      new Date(Date.now()),
    ).slice(0, -2)}01`,
  );
  return data;
};

const getFolder = async (folderId) => {
  const { data } = await axios.get(`/api/folder/${folderId}/`);
  return data;
};

const getFolders = async () => {
  const { data } = await axios.get(`/api/folder/`);
  return data;
};

const getHaccpAlerts = async () => {
  const { data } = await axios.get(`/api/haccp/alert/?fields=*&limit=100`);
  return data;
};

const getHaccpFoods = async () => {
  const { data } = await axios.get(`/api/v2/haccp/foods/?fields=*&limit=1000`);
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getHaccpHazards = async () => {
  const { data } = await axios.get(
    `/api/v2/haccp/hazards/?fields=*&limit=1000`,
  );
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getHaccpSources = async () => {
  const { data } = await axios.get(`/api/v2/haccp/sources/?fields=*&limit=100`);
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getInterests = async () => {
  const { data } = await axios.get(
    `/api/v2/pages/?type=home.ContentPage&fields=*&slug=interests`,
  );
  return data;
};

export const getItem = async (params, subdomain) => {
  const subdomainUrl = subdomain === 'haccp' ? '/api/v2/haccp' : '/api/v2';
  const { data } = await axios.get(
    `${subdomainUrl}/pages/${params.id}/?limit=1000${params?.source}`,
  );
  return data;
};

export const getItems = async (filter, pageParam, subdomain) => {
  const offset = pageParam * 10;
  let url;
  if (subdomain === 'fl') {
    url = `/api/v2/pages/?type=home.Entry&fields=*&offset=${offset}&limit=10`;
    if (filter) {
      url = `/api/v2/pages/?type=home.Entry&fields=*&offset=${offset}&limit=10${filter}`;
    }
  }
  if (subdomain === 'pack') {
    url = `/api/v2/pages/?type=packaging.PackagingEntry&fields=*&offset=${offset}&limit=10`;
    if (filter) {
      url = `/api/v2/pages/?type=packaging.PackagingEntry&fields=*&offset=${offset}&limit=10${filter}`;
    }
  }
  if (subdomain === 'haccp') {
    url = `/api/v2/haccp/pages/?type=haccp.HaccpEntry&fields=*&offset=${offset}&limit=10`;
    if (filter) {
      url = `/api/v2/haccp/pages/?type=haccp.HaccpEntry&fields=*&offset=${offset}&limit=10${filter}`;
    }
  }
  const { data } = await axios.get(url);
  return data;
};

const getPackagingApplications = async () => {
  const { data } = await axios.get(
    `/api/v2/pack/applications/?fields=*&limit=100`,
  );
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getPackagingCustomWatch = async (customWatchId) => {
  const { data } = await axios.get(`/api/pack/watch/${customWatchId}/`);
  return data;
};

const getPackagingCustomWatches = async () => {
  const { data } = await axios.get(`/api/pack/watch/?fields=*&limit=100`);
  return data;
};

const getPackagingSources = async () => {
  const { data } = await axios.get(`/api/v2/pack/sources/?fields=*&limit=100`);
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getPackagingStatus = async () => {
  const { data } = await axios.get(`/api/v2/pack/status/?fields=*&limit=100`);
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getPackagingTags = async () => {
  const { data } = await axios.get(`/api/v2/pack/tags/?fields=*&limit=1000`);
  return data;
};

const getPackagingTopics = async () => {
  const { data } = await axios.get(`/api/v2/pack/topics/?fields=*&limit=100`);
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

const getSources = async () => {
  const { data } = await axios.get(`/api/v2/sources/?fields=*&limit=100`);
  return data;
};

const getTextOfInterest = async (slug) => {
  const { data } = await axios.get(
    `/api/v2/pages/?type=haccp.HaccpTextOfInterest&fields=*&slug=${slug}`,
  );
  return data;
};

const getTextsOfInterest = async () => {
  const { data } = await axios.get(
    `/api/v2/pages/?type=haccp.HaccpTextOfInterest&fields=*`,
  );
  return data;
};

const getTextTypes = async () => {
  const { data } = await axios.get(`/api/v2/source_types/?fields=*&limit=100`);
  return data;
};

const getTags = async () => {
  const { data } = await axios.get(`/api/v2/tags/?fields=*&limit=1000`);
  return data;
};

const getTopics = async () => {
  const { data } = await axios.get(`/api/v2/topics/?fields=*&limit=100`);
  data.items = data.items.filter((item) => item.depth !== 1);
  return data;
};

export const useAboutQuery = () =>
  useQuery(['about'], () => getAbout(), {
    refetchOnWindowFocus: false,
  });

export const useCurrentUserQuery = () =>
  useQuery(['current-user'], () => getCurrentUser(), {
    refetchOnWindowFocus: false,
  });

export const useCustomWatchQuery = (customWatchId) =>
  useQuery(
    ['custom-watch', customWatchId],
    () => getCustomWatch(customWatchId),
    {
      refetchOnWindowFocus: false,
    },
  );

export const useCustomWatchesQuery = () =>
  useQuery(['custom-watches'], () => getCustomWatches(), {
    refetchOnWindowFocus: false,
  });

export const useDisclaimerQuery = () =>
  useQuery(['disclaimer'], () => getDisclaimer(), {
    refetchOnWindowFocus: false,
  });

export const useDomainsQuery = () =>
  useQuery(['domains'], () => getDomains(), {
    refetchOnWindowFocus: false,
  });

export const useFocusQuery = () => {
  const { setHasFocusToDisplay } = useAppContext();

  return useQuery(['focus'], () => getFocus(), {
    enabled: getSubdomain() === 'fl',
    onSuccess: (data) => {
      const oldData = JSON.parse(localStorage.getItem('oldFocus'));
      if (
        data.items.length > 0 &&
        (!oldData ||
          JSON.stringify(oldData.items) !== JSON.stringify(data.items))
      ) {
        localStorage.setItem('oldFocus', JSON.stringify(data));
        setHasFocusToDisplay(true);
      }
    },
    refetchOnWindowFocus: true,
  });
};

export const useFolderQuery = (folderId) =>
  useQuery(['folder', folderId], () => getFolder(folderId), {
    refetchOnWindowFocus: false,
  });

export const useFoldersQuery = () =>
  useQuery(['folders'], () => getFolders(), {
    refetchOnWindowFocus: false,
  });

export const useHaccpAlertsQuery = () =>
  useQuery(['haccp-alerts'], () => getHaccpAlerts(), {
    refetchOnWindowFocus: false,
  });

export const useHaccpFoodsQuery = () =>
  useQuery(['haccpFoods'], () => getHaccpFoods(), {
    refetchOnWindowFocus: false,
  });

export const useHaccpHazardsQuery = () =>
  useQuery(['haccpHazards'], () => getHaccpHazards(), {
    refetchOnWindowFocus: false,
  });

export const useHaccpSourcesQuery = () =>
  useQuery(['haccpSources'], () => getHaccpSources(), {
    refetchOnWindowFocus: false,
  });

export const useInterestsQuery = () =>
  useQuery(['interests'], () => getInterests(), {
    refetchOnWindowFocus: false,
  });

export const useItemQuery = (params) => {
  const subdomain = getSubdomain();
  return useQuery(['item', params], () => getItem(params, subdomain), {
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!params.id,
  });
};

export const useItemsQuery = (filter) => {
  const subdomain = getSubdomain();
  return useInfiniteQuery(
    ['items', filter],
    ({ pageParam = 0 }) => getItems(filter, pageParam, subdomain),
    {
      refetchInterval: 1000 * 3600,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage, pages) =>
        lastPage.meta.total_count > pages.length * 10
          ? pages.length
          : undefined,
    },
  );
};

export const usePackagingApplicationsQuery = () =>
  useQuery(['packagingApplications'], () => getPackagingApplications(), {
    refetchOnWindowFocus: false,
  });

export const usePackagingCustomWatchQuery = (customWatchId) =>
  useQuery(
    ['packaging-custom-watch', customWatchId],
    () => getPackagingCustomWatch(customWatchId),
    {
      refetchOnWindowFocus: false,
    },
  );

export const usePackagingCustomWatchesQuery = () =>
  useQuery(['packaging-custom-watches'], () => getPackagingCustomWatches(), {
    refetchOnWindowFocus: false,
  });

export const usePackagingSourcesQuery = () =>
  useQuery(['packagingSources'], () => getPackagingSources(), {
    refetchOnWindowFocus: false,
  });

export const usePackagingStatusQuery = () =>
  useQuery(['packagingStatus'], () => getPackagingStatus(), {
    refetchOnWindowFocus: false,
  });

export const usePackagingTagsQuery = () =>
  useQuery(['packagingTags'], () => getPackagingTags(), {
    refetchOnWindowFocus: false,
  });

export const usePackagingTopicsQuery = () =>
  useQuery(['packagingTopics'], () => getPackagingTopics(), {
    refetchOnWindowFocus: false,
  });

export const useSourcesQuery = () =>
  useQuery(['sources'], () => getSources(), { refetchOnWindowFocus: false });

export const useTagsQuery = () =>
  useQuery(['tags'], () => getTags(), {
    refetchOnWindowFocus: false,
  });

export const useTextTypesQuery = () =>
  useQuery(['textTypes'], () => getTextTypes(), {
    refetchOnWindowFocus: false,
  });

export const useTextOfInterestQuery = (slug) =>
  useQuery(['textOfInterest', slug], () => getTextOfInterest(slug), {
    refetchOnWindowFocus: false,
  });

export const useTextsOfInterestQuery = () =>
  useQuery(['textsOfInterest'], () => getTextsOfInterest(), {
    refetchOnWindowFocus: false,
  });

export const useTopicsQuery = () =>
  useQuery(['topics'], () => getTopics(), { refetchOnWindowFocus: false });
